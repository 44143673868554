
import http from '@/utils/http';

// 获取
export const getUrlGroup = (params) => {
    return http.request({
        url: '/v1/cms_api/custom/url_group/list',
        method: 'get',
        params: params
    })
}

// 更新
export const editUrlGroup = (params) => {
    return http.request({
        url: '/v1/cms_api/custom/url_group/edit',
        method: 'post',
        data: params
    })
}


export const getUrlGroupInfos = (params) => {
    return http.request({
        url: '/v1/cms_api/custom/url_group/info',
        method: 'get',
        params: params
    })
}