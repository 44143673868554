<template>
    <AutoComplete
        v-model="search_key"
        placeholder="搜索"
        @on-change = "doSearch"
        @on-blur  = 'blur'
    >
        <Option v-for="option in datas" :value="option.email" :tag="option.id" :key="option.id" @click.native="onClicked(option)">
            <span class="text-link">{{ option.id }}:</span> <span class="text-main">{{ option.email }}</span>
        </Option>
    </AutoComplete>
</template>

<script>

import { searchThesportsUser } from '@/api/custom/thesports_user';

export default {
    name: "thesports-user-search",
    props: {
        value     : Object,    // {id, email, logo}
    },
    components: {
    },
    data () {
        let self = (window.x = this);
        return {
            search_key: '',
            data: {
                'id': 0,
                'email': '',
                'logo': '',
            },
            datas: {},
            loading: false,
        }
    },
    methods: {
        doSearch (value) {
            let self = this;
            // console.log("unique_touremailnt SEARCH:", value,  self.value);
            if (value && (value !== self.data.email)){
                self.loading = true;
                let params = {
                    search_key: value,
                }
                searchThesportsUser(params).then(response => {
                    if (response.data.code == 0) {
                        self.datas = response.data.data.list;
                    } else {
                        this.$Message.error(response.data.msg);
                    }
                })
                self.loading = false;
            }else if (value && ( value === self.data.email)){
                self.datas = {}
                self.datas [self.data.id]= self.data
            }
        },
        // end
        onClicked(data){
            // console.log("onClicked(option)", option)
            let self = this;
            self.data = data;
            self._emit();
            self.search_key = self.data.email;
        },
        // end
        _emit(){
            this.$emit('input', this.data);
        },
        // end
        blur(){
            // 失去焦点时触发
            let self = this;
            if (self.search_key){
                self.search_key = self.data.email;
            }else{
                self.data = {
                    id:0,
                    email: '',
                    logo: ''
                };
                self.datas = [];
            }
            self._emit();
        },
        // end
    },
    mounted () {
    },
    watch: {
        'value': {
            handler: function(value){
                this.data = value;
                this.search_key = value.email;
            },
            deep: true,
        },
        // end 
    },
    computed: {
    }
}
</script>
<style scoped>
    /* @import "search.css" */
</style>
