<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
    >
        <customEdit :custom_name="edit_custom_name" @closePage="closeEditPage"></customEdit>
    </Drawer>  

    <Row style="margin-bottom:10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="20" style="text-align: right">
            <Button type="success"  @click="edit_custom_name='';drawer_edit=true">新增</Button>
        </Col>
    </Row>
    <br />
    <Table 
        max-height="600" 
        stripe 
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </Table>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getCustom } from '@/api/custom/custom';
import customEdit from './edit.vue';

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id)
                // 删除本行
                vm.data.splice(params.index, 1)
            }
        }
        },
        [
            h(
                'Button',
                {
                    style: {
                        margin: '2px 2px'
                    },
                    props: {
                        type: 'warning',
                        size: 'small'
                    }
                },
                '删除'
            )
        ]
    )
}

const editButton = (vm, h, params) => {
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_custom_name = params.row.name;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "custom-list",
    // props: {
    //     sport_id: Number,   // 运动id
    // },
    components: {
        customEdit
    },
    data () {
        let self = (window.x = this)
        return {
            drawer_edit: false,
            edit_custom_name: '',
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            columns: [
                {
                    title: '名称',
                    align: 'center',
                    minWidth: 80,
                    key: 'name',
                },
                // {
                //     title: '账号有效时间',
                //     minWidth: 80,
                //     align: 'center',
                //     key: 'expire',
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         return h( 'span', moment.unix(currentRow.expire).format('YYYY-MM-DD HH:mm') )
                //     }
                // },
                {
                    title: '注册用户邮箱',
                    minWidth: 100,
                    align: 'center',
                    key: 'user',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return [
                            h('span', {class: 'text-link',}, currentRow.user.id || ''),
                            h('span', ' '),
                            h('span', currentRow.user.email || '' ),
                        ]
                    }
                },
                {
                    title: '账号类型',
                    minWidth: 80,
                    align: 'center',
                    key: 'type',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h( 'span', currentRow.type === 0? '测试': (currentRow.type === 1? '正式': '正式到期') )
                    }
                },
                {
                    title: '权限包',
                    align: 'left',
                    key: 'url_groups',
                    minWidth: 300,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let url_groups = currentRow.url_groups || [];
                        if (url_groups && url_groups.length > 0){
                            var _tree = []
                            url_groups.forEach((element, _index) => {
                                let tree_data = {
                                    // title: element.group_name,
                                    expand: false,
                                    children: [],
                                    render: (h, { root, node, data }) => {
                                        return [
                                            h('span',
                                                {
                                                    class: 'text-main',
                                                },
                                                element.group_name || '',
                                            ),
                                        ]
                                    }
                                };
                                if (element.url_infos && element.url_infos.length > 0){
                                    element.url_infos.forEach(url_data => {
                                        tree_data.children.push({
                                            // title: url_data.name + ' ' + url_data.url,
                                            // expand: true,
                                            render: (h, { root, node, data }) => {
                                                return [
                                                    h('span',
                                                        {
                                                            class: 'text-assist',
                                                        },
                                                        url_data.name + ' ',
                                                    ),
                                                    h('span',
                                                        {
                                                            class: 'text-link',
                                                        },
                                                        url_data.url,
                                                    ),
                                                ]
                                            }
                                        })
                                    })
                                }
                                _tree.push( tree_data )
                            });
                            return h("Tree", 
                                {
                                    props: {
                                        data: _tree,
                                    }
                                }
                            )
                        }else{
                            return h( 'span', '无权限' )
                        }
                    }
                },
                {
                    title: 'ip数',
                    minWidth: 100,
                    align: 'center',
                    key: 'ip_num',
                },
                {
                    title: '白名单IP',
                    align: 'left',
                    key: 'ips',
                    width: 180,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let ips = currentRow.ips || [];
                        if (ips && ips.length > 0){
                            var _texts =  [ ]
                            ips.forEach((element, _index) => {
                                _texts.push( 
                                    h("Row", [ h( 'span', element) ] )
                                )
                            });
                        }else{
                            var _texts = [];
                        }
                        return _texts
                    }
                },
                {
                    title: '动画域名数',
                    minWidth: 80,
                    align: 'center',
                    key: 'domain_num_animation',
                },
                {
                    title: '动画域名',
                    align: 'left',
                    key: 'domains_animation',
                    width: 180,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let domains_animation = currentRow.domains_animation || [];
                        if (domains_animation && domains_animation.length > 0){
                            var _texts =  [ ]
                            domains_animation.forEach((element, _index) => {
                                _texts.push( 
                                    h("Row", [ h( 'span', element) ] )
                                )
                            });
                        }else{
                            var _texts = [];
                        }
                        return _texts
                    }
                },
                {
                    title: '推流域名数',
                    align: 'center',
                    minWidth: 80,
                    key: 'domain_num_livestream',
                },
                {
                    title: '推流域名',
                    align: 'left',
                    key: 'domains_livestream',
                    width: 180,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let domains_livestream = currentRow.domains_livestream || [];
                        if (domains_livestream && domains_livestream.length > 0){
                            var _texts =  [ ]
                            domains_livestream.forEach((element, _index) => {
                                _texts.push( 
                                    h("Row", [ h( 'span', element) ] )
                                )
                            });
                        }else{
                            var _texts = [];
                        }
                        return _texts
                    }
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 120,
                    render (h, params) {
                        return [ editButton(self, h, params), ]
                    }
                }
            ],
            data: [],
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        getData () {
            this.loading = true;
            // 搜索日期
            var params = {
                search_key: this.search_key,
                page: this.current_page,
            }
            getCustom(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        closeEditPage(){
            let self = this;
            self.drawer_edit = false;
            self.getData();
        },
        // end 
    },
    mounted () {
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "list.css" */
</style>