<template>
    <Transfer
        :data="source_urls"
        :target-keys="target_urls"
        :render-format="render_way"
        @on-change="handleChange"
        filterable
        :filter-method='filterMethod'
        :list-style="listStyle"
    >
    </Transfer>
</template>

<script>

import { getUrlInfos } from '@/api/custom/service_url';

export default {
    name: "service-url-picker",
    props: {
        group_id  : Number,   // 权限包id
        value     : Array,   // 
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            source_urls: [],
            source_urls_map: {},
            target_urls: [],
            listStyle: {
                width: '45%',
                'min-height': '500px'
            },
        }
    },
    methods: {
        render_way(item){
            return item.name + ' ('+ item.key +')';
        },
        // end
        handleChange (newTargetUrls) {
            // console.log(newTargetUrls);
            // newTargetUrls.reverse();
            this.target_urls = newTargetUrls;
            this._emit()
        },
        // end
        _emit(){
            let new_value = [];
            this.target_urls.forEach(element => {
                new_value.push({
                    url: element,
                    name: this.source_urls_map[element] || ''
                })
            })
            this.$emit('input', new_value);
        },
        // end 
        filterMethod (data, query) {
            // console.log(data);
            return data.key.indexOf(query) > -1 || data.name.indexOf(query) > -1;
        },
        // end 
        getUrls(){
            let self = this;
            var params = {
                group_id: self.group_id,
            }
            // console.log(11111, params)
            getUrlInfos(params).then(response => {
                if (response.data.code == 0) {
                    // do nothing
                    self.source_urls = []
                    if (response.data.data && response.data.data.list.length > 0){
                        response.data.data.list.forEach(element => {
                            self.source_urls.push({
                                key: element.url,
                                name: element.name,
                                disabled: false,
                            })
                            self.source_urls_map[element.url] = element.name;
                        });
                    }
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
        // end 
    },
    mounted () {
        this.getUrls();
    },
    watch: {
        value(new_value){
            // this.target_urls = new_value;
            this.target_urls = [];
            new_value.forEach(element => {
                this.target_urls.push( element.url );
            });
        },
        group_id(new_value){
            this.getUrls();
        },
        // end 
    },
    computed: {
    }
}
</script>
<style scoped>
    /* @import "detail.css" */
</style>