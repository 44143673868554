import http from '@/utils/http';

// 搜索
export const searchThesportsUser = (params) => {
    return http.request({
        url: '/v1/cms_api/custom/thesports_user/search',
        method: 'get',
        params: params,
    })
}
