<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Modal
            v-model="modal_pickurl"
            title="编辑路由"
            footer-hide
            :width="80"
        >
            <serviceUrlPicker v-model="edit_group.url_infos" :group_id="edit_group.group_id"></serviceUrlPicker>
        </Modal>
        <!-- <Modal
            v-model="modal_password"
            title="设置/修改密码"
            @on-ok="checkPassword()"
            @on-cancel='clearPassword()'
        >
            <Form label-position="left" :label-width="100" >
                <FormItem label="输入新密码: " >
                    <Input v-model="new_password" type="password" password placeholder="设置新密码"  />
                </FormItem>
                <FormItem label="确认新密码: " >
                    <Input v-model="new_password_confirm" type="password" password placeholder="重新输入密码以确认"  />
                </FormItem>
            </Form>
        </Modal> -->

        <Form label-position="left" :label-width="70" >
            <FormItem label="用户名: " >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name" :disabled="Boolean(custom_name)"></Input>
                    </Col>
                </Row>
                <span class='text-assist'>用户名一旦保存不能修改</span>
            </FormItem>

            <FormItem label="注册用户邮箱搜索: " >
                <Row>
                    <Col span="20" >
                        <thesportsUserSearch v-model="data.user"> </thesportsUserSearch>
                    </Col>
                </Row>
            </FormItem>


            <FormItem label="密码: ">
                <Row>
                    <Col span="20" >
                        <!-- <Button type="error" @click="modal_password=true" long size="default">设置/修改密码</Button> -->
                        <Input v-model="data.password" ></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="类型: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.type">
                            <Option :value="0" :key="0" label="测试用户"></Option>
                            <Option :value="1" :key="1" label="正式用户"></Option>
                            <Option :value="2" :key="2" label="正式到期用户"></Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
            
            <!-- <FormItem label="过期时间: ">
                <Row>
                    <Col span="20" >
                        <DatePicker 
                            type="datetime" 
                            format="yyyy-MM-dd HH:mm" 
                            style="width: 100%"
                            v-model="expire"
                        >
                        </DatePicker>
                    </Col>
                </Row>
            </FormItem> -->

            <FormItem label="ip数: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.ip_num" ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="白名单IP: ">
                <Row v-for="(item, index) in data.ips" :key="index" >
                    <Col span="20" >
                        <Input v-model="data.ips[index]" ></Input>
                    </Col>
                    <Col span="4" >
                        <Icon type="md-close-circle"  @click.native="data.ips.splice(index, 1)" />
                    </Col>
                </Row>
                <Row>
                    <Col span="20" >
                        <Button shape="circle" icon="md-add" @click="data.ips.push('')" ></Button>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="动画域名数: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.domain_num_animation" ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="动画域名: ">
                <Row v-for="(item, index) in data.domains_animation " :key="index" >
                    <Col span="20" >
                        <Input v-model="data.domains_animation[index]" ></Input>
                    </Col>
                    <Col span="4" >
                        <Icon type="md-close-circle"  @click.native="data.domains_animation.splice(index, 1)" />
                    </Col>
                </Row>
                <Row>
                    <Col span="20" >
                        <Button shape="circle" icon="md-add" @click="data.domains_animation.push('')" ></Button>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="推流域名数: ">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.domain_num_livestream" ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="推流域名: ">
                <Row v-for="(item, index) in data.domains_livestream " :key="index" >
                    <Col span="20" >
                        <Input v-model="data.domains_livestream[index]" ></Input>
                    </Col>
                    <Col span="4" >
                        <Icon type="md-close-circle"  @click.native="data.domains_livestream.splice(index, 1)" />
                    </Col>
                </Row>
                <Row>
                    <Col span="20" >
                        <Button shape="circle" icon="md-add" @click="data.domains_livestream.push('')" ></Button>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="权限包: ">
                <Row v-for="(item, index) in data.url_groups" :key="index" >
                    <Col span="22" >
                        <!-- 权限包名称 -->
                        <Row>
                            <Col :span="16">
                                <Select v-model="item.group_id" @on-change='(event) => checkGroupId(event, index)'>
                                    <Option v-for="_gro in all_groups" :value="_gro.id" :key="_gro.id" :label="_gro.name" :disabled="checkGroupExist(_gro.id)"  ></Option>
                                </Select>
                            </Col>
                            <Col :span="4">
                                <Button v-if=" item.group_id && item.group_id>0 " type="primary"  @click.native="editGroupUrls( data.url_groups[index] )" >编辑路由</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="6">
                                <Select v-model="item.type" placeholder="正式/测试">
                                    <Option :value="0" :key="0" label="测试"></Option>
                                    <Option :value="1" :key="1" label="正式"></Option>
                                </Select>
                            </Col>
                            <Col :span="12"> 
                                <DatePicker 
                                    :ref=" 'formDate_'+index"
                                    type="datetime" 
                                    format="yyyy-MM-dd HH:mm" 
                                    v-model="data.url_groups[index].expire_str" 
                                    placeholder="过期时间" 
                                    @on-change='(event) => onExpireChange(event, index)'
                                > 
                                </DatePicker> 
                            </Col>
                            <Col :span="5" :offset="1">
                                <Checkbox v-model="item.forever" @on-change='(event) => onForeverChange(event, index)' >永久</Checkbox>
                            </Col>
                        </Row>
                        <!-- 自定义的路由地址 -->
                        <template v-if="item.url_infos && item.url_infos.length > 0">
                            <!-- <Row><span class='text-main'>路由地址列表:</span> </Row> -->
                            <Row v-for="(url, index2) in item.url_infos" :key="index2"> 
                                <Col span="8" >
                                    <span class="text-main"> {{url.name}} </span>
                                </Col>
                                <Col span="12" >
                                    <span class="text-link"> {{url.url}} </span>
                                </Col>
                                <Col span="4" >
                                    <Icon type="md-close-circle"  @click.native="item.url_infos.splice(index2, 1)" />
                                </Col>
                            </Row>
                        </template>
                    </Col>
                    <Col span="2" >
                        <Icon type="md-close-circle"  @click.native="data.url_groups.splice(index, 1)" />
                    </Col>
                </Row>
                <Row>
                    <Col span="20" >
                        <Button shape="circle" icon="md-add" @click="data.url_groups.push({group_id:0, url_infos:[] })" ></Button>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="是否删除: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.deleted" >
                            <Option :value="1" key="1">是</Option>
                            <Option :value="0" key="0">否</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getCustom, editCustom} from '@/api/custom/custom';
import { getUrlGroupInfos } from '@/api/custom/service_url_group';
import moment from 'moment';
import serviceUrlPicker from '../basic/service_url_picker.vue';
import thesportsUserSearch from '../thesports_user/search.vue';

export default {
    name: "custom-edit",
    props: {
        custom_name: String,   // 客户名称
    },
    components: {
        serviceUrlPicker,
        thesportsUserSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            spinShow: false,
            modal_password: false,
            new_password: '',
            new_password_confirm: '',
            modal_pickurl: false,
            edit_group: {
                group_id: 0,
                url_infos: []
            },
            all_groups: [],
            data: {
                ips: [],
                domains_animation: [],
                domains_livestream: [],
                deleted: 0,
                expire: parseInt( Date.parse(new Date())/1000 ),
                url_groups: [],
                user: {},
            },
        }
    },
    methods: {
        checkGroupExist(group_id){
            let self = this;
            let status = false
            self.data.url_groups.forEach(element => {
                if (element && element.group_id == group_id){
                    status = true;
                    return status
                }
            })
            return status;
        },
        onForeverChange(forever, index){
            let self = this;
            if (forever){
                self.$refs['formDate_'+index][0].handleClear(); //自带清空函数
                self.$set(self.data.url_groups[index], 'expire_str', '');
                self.$set(self.data.url_groups[index], 'expire', 0);
            }
        },
        onExpireChange(expire_str, index){
            let self = this;
            self.data.url_groups[index].expire = moment(expire_str).unix();
        },
        checkGroupId(group_id, index){
            let self = this;
            // 情况所有路由
            self.data.url_groups[index].url_infos = [];

            let counter = 0;
            self.data.url_groups.forEach(element => {
                if (element.group_id === group_id){
                    counter += 1
                }
            });
            if (counter > 1){
                self.$Message.error('权限包重复')
                return
            }
        },
        // end 
        getUrlGroups(){
            getUrlGroupInfos().then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    if (response.data.data.list ){
                        this.all_groups = response.data.data.list;
                    }else{
                        this.all_groups = []
                    }
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end 
        clearPassword(){
            let self = this;
            self.new_password = '';
            self.new_password_confirm = '';
        },
        // end 
        checkPassword(){
            let self = this;
            if (self.new_password.length >0 || self.new_password_confirm.length > 0){
                if (self.new_password.length < 8){
                    self.$Message.error("密码长度至少为8个字符")
                    // self.modal_password = true;
                    return
                }
                if (self.new_password !== self.new_password_confirm){
                    self.$Message.error("密码不一致")
                    // self.modal_password = true;
                    return
                }
                self.$Message.success("密码设置成功")
                self.data.password = self.new_password;
                self.clearPassword();
            }
        },
        // end 
        getData () {
            let self = this;
            if (self.custom_name && self.custom_name.length > 0){
                // console.log(self.custom_name);
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "name" : this.custom_name,
                    "page" : 1, 
                }
                getCustom( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            // console.log(this.data);
                            if (self.data.url_groups && self.data.url_groups.length > 0 ){
                                self.data.url_groups.forEach(element => {
                                    element.expire_str = element.expire ? moment.unix(element.expire).toDate() : '';
                                })
                            }
                        }else{
                            this.data = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // alert(1)
                self.data =  {
                    ips: [],
                    deleted: 0,
                    expire: parseInt( Date.parse(new Date())/1000 ),
                    url_groups: [],
                    user: {},
                }
            }
            
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },
        // end 
        save() {
            let self = this;
            // console.log(self.data.url_groups);
            let url_groups = {};
            // 1. 过期时间 expire
            // 2. 定制化路由 urls
            // 3. 同2: 永久有效 forever
            // 4. 正式/测试 _type
            if ( self.data.url_groups){
                // console.log( self.data.url_groups );
                self.data.url_groups.forEach(element => {
                    if (url_groups[element.group_id]){
                        // 是否存在重复的权限包
                        self.$Message.error("存在重复的权限包")
                        return
                    }
                    let forever = element.forever ;     // 
                    url_groups[element.group_id] = {'urls': [], 'expire': 0, 'type':0, 'forever': false };
                    url_groups[element.group_id]['forever'] = forever;
                    if (forever){
                        url_groups[element.group_id]['expire'] = 0;
                    } else {
                        if (element.expire_str){
                            url_groups[element.group_id]['expire'] = moment( element.expire_str ).unix() || 0;
                        }else{
                            url_groups[element.group_id]['expire'] = 0;
                        }
                    }
                    // type
                    url_groups[element.group_id]['type'] = element.type || 0;
                    // urls
                    if (element.url_infos && element.url_infos.length > 0){
                        element.url_infos.forEach(url_data => {
                            url_groups[element.group_id].urls.push(url_data.url);
                        })
                        // console.log( url_groups );
                    }else{
                        if (element.group_id > 0){
                            // url_groups[element.group_id] = {};
                            delete url_groups[element.group_id]['urls']
                        }
                    }
                })
            }

            let params = {
                name: self.data.name,
                password: self.data.password,
                type: self.data.type,
                ips: self.data.ips,
                deleted: self.data.deleted,
                user_id: self.data.user.id,
                url_groups: url_groups,
                ip_num: self.data.ip_num,
                domains_animation: self.data.domains_animation,
                domain_num_animation: self.data.domain_num_animation,
                domains_livestream: self.data.domains_livestream,
                domain_num_livestream: self.data.domain_num_livestream,
            }
            // console.log(params);
            editCustom(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end
        editGroupUrls(group_item){
            // console.log(group_item);
            let self = this;
            if (group_item.group_id && group_item.group_id > 0){
                self.edit_group = group_item;
                self.modal_pickurl = true;
            }else{
                this.$Message.error('需要先选择权限包')
            }
        },
        // end
    },
    mounted () {
        // let self = this;
        this.getUrlGroups();
    },
    watch: {
        'custom_name': {
            handler(value){
                let self = this;
                // if (value.length >= 0){
                // console.log("Detail Get Data", value);
                self.getData();
                // };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
        expire: {
            get: function () {
                if (this.data.expire){
                    return moment.unix(this.data.expire).toDate();
                }else{
                    return new Date(); // parseInt( Date.parse(new Date())/1000 ) ;
                }
            },
            set: function (newValue) {
                this.data.expire = moment(newValue).unix();
            }
        },
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>